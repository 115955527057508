import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import {
  ChangePasswordRequestModel,
  ForgotPasswordRequestModel,
  LoginRequestModel,
  LoginResponseModel,
  RegistrationRequestModel,
  ResetPasswordModel
} from '../models/auth.models';
import {
  CheckVerificationRequest,
  SendVerificationCodeModelRequest,
  SuccessModel
} from '../models/code.models';
import { tap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isLoggedInSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isLoggedIn$: Observable<boolean> = this.isLoggedInSubject$.asObservable();

  constructor(
    private apiService: BaseApiService,
    private storage: StorageService,
    private router: Router
  ) {
    const user = this.storage.getUser();
    if (user) {
      if (user.emailConfirmed && user.cardId) {
        this.isLoggedInSubject$.next(true);
      }
    }
  }

  login(model: LoginRequestModel) {
    return this.apiService.post<LoginResponseModel>('account/login', model)
      .pipe(tap({
        next: response => {
          if (response.data) {
            this.storage.clearLoginData();
            this.storage.setLoginData(response.data);
            if (response.data.user.emailConfirmed && response.data.user.cardId) {
              this.isLoggedInSubject$.next(true);
            }
          }
        }
      }));
  }

  logout() {
    this.storage.clearLoginData();
    this.isLoggedInSubject$.next(false);
    this.router.navigate(['/auth', 'login']);
  }

  registerUser(model: RegistrationRequestModel) {
    return this.apiService.post<LoginResponseModel>('account/registration', model)
      .pipe(tap({
        next: response => {
          if (response.data) {
            this.storage.clearLoginData();
            this.storage.setLoginData(response.data);
            if (response.data.user.emailConfirmed && response.data.user.cardId) {
              this.isLoggedInSubject$.next(true);
            }
          }
        }
      }));
  }

  finishRegistration() {
    this.isLoggedInSubject$.next(true);
  }

  isEmailUniq(email: string) {
    const query = `account/is-email-unique?email=${email}`;
    return this.apiService.get<any>(query);
  }

  sendCode(model: SendVerificationCodeModelRequest) {
    return this.apiService.post<SuccessModel>('account/send-code', model);
  }

  checkCode(model: CheckVerificationRequest) {
    return this.apiService.post<SuccessModel>('account/check-code', model);
  }

  changePassword(model: ChangePasswordRequestModel) {
    return this.apiService.post<LoginResponseModel>('account/change-password', model);
  }

  forgot(model: ResetPasswordModel) {
    return this.apiService.post<SuccessModel>('account/forgot', model);
  }

  forgotCheckCode(model: CheckVerificationRequest) {
    return this.apiService.post<SuccessModel>('account/forgot/check-code', model);
  }

  forgotChangePassword(model: ForgotPasswordRequestModel) {
    return this.apiService.post<LoginResponseModel>('account/forgot/change-password', model);
  }
}
