import {
	Component,
	HostBinding,
	Input,
	ContentChildren,
	AfterContentInit,
	QueryList,
	OnChanges,
	OnInit,
	ChangeDetectorRef,
	Output, EventEmitter
} from '@angular/core';
import { TCTabComponent } from './tab/tab.component';


@Component({
	selector: 'tc-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss']
})
export class TCTabsComponent implements AfterContentInit, OnChanges, OnInit {
	@ContentChildren(TCTabComponent) tabs: QueryList<TCTabComponent>;
	@HostBinding('class.tc-tabs') true;

	@HostBinding('class.default') get getDefaultView() {
		return this.view === 'default';
	}

	@HostBinding('class.alternative') get getAlternativeView() {
		return this.view === 'alternative';
	}

	@HostBinding('class.steps') get getStepsView() {
		return this.view === 'steps';
	}

	@HostBinding('class.steps-simple') get getStepsAdditView() {
		return this.stepsView === 'simple';
	}

	@HostBinding('class.steps-without-numbers') get getStepsWithoutTitleView() {
		return this.stepsView === 'without-title';
	}

	@Input() color: string;
	@Input() view: string;
	@Input() stepsView: string;
	@Input() selectedTab: number;
	max: number;

	constructor(private changeDetector: ChangeDetectorRef) {
		this.view = 'default';
		this.max = 2;
		this.selectedTab = 1;
	}

	ngOnInit() {
	}

	ngAfterContentInit() {
		this.max = this.tabs.length;
		// let activeTabs = this.tabs.filter((tab) => tab.active);
		// if (activeTabs.length === 0) {
		// 	this.selectTab(this.tabs.first);
		// }
		this.initActiveTabs();
		this.changeDetector.detectChanges();
	}

	ngOnChanges() {
		this.initActiveTabs();
	}

	initActiveTabs() {
		if (this.tabs && this.tabs.length > 0) {
			this.tabs.toArray().forEach(tab => {
				tab.active = false;
				tab.completed = false;
			});
			this.tabs.toArray()[this.selectedTab - 1].active = true;
			if (this.view === 'steps') {
				this.tabs.toArray().forEach(function(tab, i) {
					if (i < this.selectedTab - 1) {
						tab.completed = true;
					}
				}.bind(this));
			}
		}
	}

	selectTab(tab: TCTabComponent) {
		this.tabs.toArray().forEach(tab => tab.active = false);
		tab.active = true;
		this.selectedTab = this.tabs.toArray().indexOf(tab) + 1;
	}

	getTabClasses(tab: TCTabComponent) {
		return {
			'disabled': tab.disabled,
			'active': tab.active,
			'completed': tab.completed && !tab.active,
			'icon': tab.icon != null
		};
	}
}
