import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { ContentBannerComponent } from './components/content-banner/content-banner.component';
import { MenuComponent } from './components/menu';
import { FooterComponent } from './components/footer';
import { LandingComponent } from './landing.component';
import { HeaderComponent } from './components/header/header.component';
import { TermsPageComponent } from './terms-page/terms-page.component';
import { PolicyPageComponent } from './policy-page/policy-page.component';
import { HomeComponent } from './home/home.component';
import { WorldMapComponent } from './components/world-map/world-map.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule
  ],
  declarations: [
    MenuComponent,
    FooterComponent,
    LandingComponent,
    HeaderComponent,
    TermsPageComponent,
    PolicyPageComponent,
    HomeComponent,
    AboutUsPageComponent,
    ContactUsPageComponent,
    ContentBannerComponent,
    WorldMapComponent
  ],
  providers: []
})
export class LandingModule {
}
