import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from '../features/landing';
import { TermsPageComponent } from '../features/landing/terms-page/terms-page.component';
import { PolicyPageComponent } from '../features/landing/policy-page/policy-page.component';
import { HomeComponent } from '../features/landing/home/home.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { GuestGuard } from '../core/guards/guest.guard';


const ROUTES: Routes = [
  { path: 'system', canActivate: [AuthGuard], loadChildren: () => import('../features/system/system.module').then(m => m.SystemModule) },
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
      },
      // {
      //     path: 'about-us',
      //     component: AboutUsPageComponent,
      //     pathMatch: 'full'
      // },
      // {
      //     path: 'contact-us',
      //     component: ContactUsPageComponent,
      //     pathMatch: 'full'
      // },
      {
        path: 'terms',
        component: TermsPageComponent,
        pathMatch: 'full'
      },
      {
        path: 'privacy-policy',
        component: PolicyPageComponent,
        pathMatch: 'full'
      },
      { path: 'auth', canActivate: [GuestGuard], loadChildren: () => import('../features/auth/auth.module').then(m => m.AuthModule) },
      {
        path: '**',
        component: HomeComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [
    RouterModule
  ]
})
export class RoutingModule {
}
