import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { BriefUserModel, UpdateUserEmailModel } from '../models/user.models';
import { StripeCardModel } from '../models/stripe.models';
import { map, tap } from 'rxjs/operators';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private apiService: BaseApiService,
    private storage: StorageService
  ) {
  }

  updateEmail(model: UpdateUserEmailModel) {
    return this.apiService.put<BriefUserModel>('user/email', model);
  }

  getUser() {
    return this.apiService.get<BriefUserModel>('user');
  }

  setDefaultCard(id) {
    return this.apiService.put<BriefUserModel>(`user/default-card/${id}`)
      .pipe(tap({
        next: response => {
          this.storage.setUserData(response.data);
        }
      }));
  }

  attachCard(paymentMethodId) {
    return this.apiService.post<StripeCardModel[]>(`user/attach-card/${paymentMethodId}`);
  }

  detachCard(paymentMethodId) {
    return this.apiService.post<StripeCardModel[]>(`user/detach-card/${paymentMethodId}`);
  }

  getCardsList() {
    return this.apiService.get<StripeCardModel[]>('user/cards')
      .pipe(
        map(cards => {
            const user = this.storage.getUser();
            if (user) {
              const defaultCardId = user.cardId;
              const cardsList = cards.data;
              cardsList?.forEach(
                cardItem => {
                  if (cardItem.paymentMethodId === defaultCardId) {
                    cardItem.isDefault = true;
                  } else {
                    cardItem.isDefault = false;
                  }
                }
              );
              return cardsList?.sort(this.sortCardList);
            }
          }
        )
      );
  }

  private sortCardList(card1, card2) {
    if (card1.isDefault === card2.isDefault) {
      return 0;
    } else if (card1.isDefault && !card2.isDefault) {
      return 1;
    }
    return -1;
  }
}
