import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-banner',
  templateUrl: './content-banner.component.html',
  styleUrls: ['./content-banner.component.scss']
})
export class ContentBannerComponent implements OnInit {
  @Input() pageTitle: string;
  @Input() bannerContent: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
