import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import {
  BeginTransactionRequestModel,
  BeginTransactionResponseModel,
  BriefTransactionModel,
  CheckAccountNumberRequest,
  TransactionInfoModel
} from '../models/transaction.models';
import { map } from 'rxjs/operators';
import { SuccessModel } from '../models/code.models';


@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  constructor(
    private apiService: BaseApiService
  ) {
  }

  getTransactions() {
    return this.apiService.get<BriefTransactionModel[]>('transaction/list/own')
      .pipe(
        map(
          response => response.data
        )
      );
  }

  getTransactionInfo(id: number) {
    return this.apiService.get<TransactionInfoModel>(`transaction/${id}`)
      .pipe(
        map(
          response => response.data
        )
      );
  }

  checkPromoCode(code: string) {
    return this.apiService.get<SuccessModel>(`promo/check-code/${code}`);
  }

  checkPayeeAccount(model: CheckAccountNumberRequest) {
    return this.apiService.post<SuccessModel>('transaction/check-account', model);

  }

  sendCode() {
    return this.apiService.get<SuccessModel>('transaction/send-code');
  }

  beginTransaction(model: BeginTransactionRequestModel) {
    return this.apiService.post<BeginTransactionResponseModel>('transaction', model);
  }

}
