import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragulaModule } from 'ng2-dragula';

import { TCAutocompleteDirective } from './directives/autocomplete/autocomplete.directive';
import { TCBorderColorDirective } from './directives/border-color/border-color.directive';
import { TCFilterTableDirective } from './directives/filter-table/filter-table.directive';
import { TCBorderStyleDirective } from './directives/border-style/border-style.directive';
import { TCSortTableDirective } from './directives/sort-table/sort-table.directive';
import { TCBgColorDirective } from './directives/bg-color/bg-color.directive';
import { TCColorDirective } from './directives/color/color.directive';
import { TCShapeDirective } from './directives/shape/shape.directive';
import { TCFocusDirective } from './directives/focus/focus.directive';

import { TCListItemComponent } from './components/list/list-item';
import { TCColumnComponent } from './components/table/column/column.component';
import { TCProgressComponent } from './components/progress';
import { TCButtonComponent } from './components/button';
import { TCTabComponent } from './components/tabs/tab';
import { TCTableComponent } from './components/table';
import { TCListComponent } from './components/list';
import { TCTabsComponent } from './components/tabs';
import { TCInputComponent } from './components/input';
import { TCFormDescriptionComponent } from './components/form-description';
import { TCFormGroupComponent } from './components/form-group';
import { TCFormLabelComponent } from './components/form-label';
import { TCPaginationComponent } from './components/pagination';
import { TCCheckboxComponent } from './components/checkbox';
import { TCTextareaComponent } from './components/textarea';
import { TCSwitcherComponent } from './components/switcher';
import { HightlightPipe } from './pipes/hightlight/hightlight.pipe';
import { TCAutocompleteComponent } from './components/autocomplete';
import { TCRadioComponent, TCRadioOptionComponent } from './components/radio';
import { TCBadgeComponent } from './components/badge';
import { TCSelectComponent } from './components/select';
import { TCNotificationComponent, TCNotificationLayoutComponent } from './components/notification';
import { TCAlertComponent } from './components/alert';
import { TCRatingComponent } from './components/rating';
import { RestrictSymbolsDirective } from './directives/restrict-symbols/restrict-symbols.directive';
import { PhonePipe } from './pipes/phone.pipe';
import { StripeAddCardComponent } from '../features/system/components/stripe-add-card/stripe-add-card.component';
import { NgxStripeModule } from 'ngx-stripe';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DragulaModule.forRoot(),
		NgxStripeModule
	],
	declarations: [
		TCAutocompleteDirective,
		TCButtonComponent,
		TCBgColorDirective,
		TCBorderColorDirective,
		TCBorderStyleDirective,
		TCColorDirective,
		TCFocusDirective,
		TCShapeDirective,
		TCTabsComponent,
		TCTabComponent,
		TCTableComponent,
		TCColumnComponent,
		TCSortTableDirective,
		TCFilterTableDirective,
		TCProgressComponent,
		TCListComponent,
		TCListItemComponent,
		TCInputComponent,
		TCTextareaComponent,
		TCFormDescriptionComponent,
		TCFormGroupComponent,
		TCFormLabelComponent,
		TCPaginationComponent,
		TCCheckboxComponent,
		TCSwitcherComponent,
		HightlightPipe,
		TCAutocompleteComponent,
		TCRadioComponent,
		TCRadioOptionComponent,
		TCBadgeComponent,
		TCSelectComponent,
		TCNotificationComponent,
		TCNotificationLayoutComponent,
		TCAlertComponent,
		TCRatingComponent,
		RestrictSymbolsDirective,
		PhonePipe,
		StripeAddCardComponent
	],
	exports: [
		TCAutocompleteDirective,
		TCButtonComponent,
		TCBgColorDirective,
		TCBorderColorDirective,
		TCBorderStyleDirective,
		TCColorDirective,
		TCShapeDirective,
		TCTabsComponent,
		TCTabComponent,
		TCTableComponent,
		TCSortTableDirective,
		TCProgressComponent,
		TCListComponent,
		TCListItemComponent,
		TCColumnComponent,
		TCInputComponent,
		TCTextareaComponent,
		TCFormDescriptionComponent,
		TCFormGroupComponent,
		TCFormLabelComponent,
		TCCheckboxComponent,
		TCSwitcherComponent,
		HightlightPipe,
		TCAutocompleteComponent,
		TCFocusDirective,
		TCRadioComponent,
		TCRadioOptionComponent,
		TCBadgeComponent,
		TCSelectComponent,
		TCNotificationComponent,
		TCNotificationLayoutComponent,
		TCAlertComponent,
		TCRatingComponent,
		RestrictSymbolsDirective,
		PhonePipe,
		StripeAddCardComponent
	]
})
export class SharedModule {
}
