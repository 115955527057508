import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-world-map',
  templateUrl: './world-map.component.html',
  styleUrls: ['./world-map.component.scss']
})
export class WorldMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('animItem') items: QueryList<any>;
  timer: any;

  constructor(
    private ngZone: NgZone
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(
      () => {
        if (this.items) {
          let prevRandom;
          this.timer = setInterval(() => {
            let random = Math.floor(Math.random() * this.items.length);
            while (random === prevRandom) {
              random = Math.floor(Math.random() * this.items.length);
            }
            prevRandom = random;
            let currentItem = this.items.toArray()[random].nativeElement;
            if (currentItem.classList.contains('anim-active')) {
              currentItem.classList.remove('anim-active');
            } else {
              currentItem.classList.add('anim-active');
            }
          }, 1000);
        }
      }
    );

  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

}
