import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(phoneNumber, countryCode): string {
    let phoneCodeLength = 1;
    let codeLength2 = ['GBR', 'GBP'];
    let codeLength3 = ['KEN', 'KES', 'TZA', 'TZS'];
    if (codeLength2.indexOf(countryCode) !== -1) {
      phoneCodeLength = 2;
    }
    if (codeLength3.indexOf(countryCode) !== -1) {
      phoneCodeLength = 3;
    }

    return '+' + phoneNumber.slice(0, phoneCodeLength) + ' ' + phoneNumber.slice(phoneCodeLength);
  }

}
