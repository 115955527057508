import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import {
  StripeCardCvcComponent,
  StripeCardExpiryComponent,
  StripeCardNumberComponent,
  StripeService
}
  from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { UserService } from '../../../../core/services/user.service';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

@Component({
  selector: 'app-stripe-add-card',
  templateUrl: './stripe-add-card.component.html',
  styleUrls: ['./stripe-add-card.component.scss']
})
export class StripeAddCardComponent implements OnInit {
  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
  @ViewChild(StripeCardExpiryComponent) cardExp: StripeCardExpiryComponent;
  @ViewChild(StripeCardCvcComponent) cardCVV: StripeCardCvcComponent;

  @Output() addCardEmit = new EventEmitter<void>();

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        color: '#262729',
        fontWeight: '400',
        fontFamily: '"Red Hat Display", sans-serif',
        fontSize: '16px',
        lineHeight: '24px',
        '::placeholder': {
          color: '#969AA3'
        }
      },
      invalid: {
        color: '#FF5152'
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  serverErrorMessage = '';
  isLoading = false;


  constructor(
    private stripeService: StripeService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
  }

  clearForm() {
    this.card.element.clear();
    this.cardExp.element.clear();
    this.cardCVV.element.clear();
    this.serverErrorMessage = '';
    this.isLoading = false;
  }

  createCard(): void {
    this.serverErrorMessage = '';
    this.isLoading = true;
    this.stripeService.createPaymentMethod({ type: 'card', card: this.card.element })
      .pipe(
        switchMap(data => data.paymentMethod
          ? this.userService.attachCard(data.paymentMethod.id)
          : throwError(data.error.message)
        ),
        switchMap(attachData => attachData.success
          ? of(attachData.success)
          : throwError(attachData.message)),
        catchError(error => {
          this.serverErrorMessage = error;
          return of(false);
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        success => {
          if (success) {
            this.addCardEmit.emit();
            this.clearForm();
          }
        }
      );
  }

  clearServerErrorMessage() {
    this.serverErrorMessage = '';
  }
}
