import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss']
})
export class ContactUsPageComponent implements OnInit {
  bannerContent = `<p>Lorem ipsum dolor sit amet, consectetur adipiscing, amet, consectetur adipi.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing, amet, consectetu.</p>`;

  constructor() {
  }

  ngOnInit(): void {
  }

}
