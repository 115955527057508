import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy-page',
  templateUrl: './policy-page.component.html'
})
export class PolicyPageComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
