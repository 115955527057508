import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: 'input[restrictSymbols]'
})
export class RestrictSymbolsDirective  {
	@Input() restrictSymbols: string;
	constructor(private _el: ElementRef) {}

	// this._el.nativeElement.value = initialValue.replace(/[^0-9]*/g, '');


	@HostListener('input', ['$event']) onInputChange(event) {
		const initialValue = this._el.nativeElement.value;
		this._el.nativeElement.value = initialValue.replace(new RegExp(this.restrictSymbols, 'g'), '');
		if ( initialValue !== this._el.nativeElement.value) {
			event.stopPropagation();
		}
	}
}
