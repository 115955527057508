import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { Observable, of } from 'rxjs';
import { ResponseModel } from '../models/response-wrapper.model';
import { BriefCountryModel } from '../models/country.models';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  private _countries: BriefCountryModel[];
  private _supportedCountries: BriefCountryModel[];
  private _paymentCountries: BriefCountryModel[];

  get countries$(): Observable<BriefCountryModel[]> {
    if (this._countries) {
      return of(this._countries);
    } else {
      return this.fetchCountries().pipe(
        map(response => response.data),
        tap({
          next: countries => this._countries = countries
        })
      );
    }
  }

  get supportedCountries$(): Observable<BriefCountryModel[]> {
    if (this._supportedCountries) {
      return of(this._supportedCountries);
    } else {
      return this.fetchSupportedCountries().pipe(
        map(response => response.data),
        tap({
          next: countries => this._supportedCountries = countries
        })
      );
    }
  }

  get paymentCountries$(): Observable<BriefCountryModel[]> {
    if (this._paymentCountries) {
      return of(this._paymentCountries);
    } else {
      return this.fetchPaymentCountries().pipe(
        map(response => response.data.sort(this.sortPaymentCountries)),
        tap({
          next: countries => this._paymentCountries = countries
        })
      );
    }
  }

  constructor(
    public apiService: BaseApiService
  ) {
  }

  private fetchCountries() {
    return this.apiService.get<BriefCountryModel[]>('country/list');
  }

  private fetchSupportedCountries(): Observable<ResponseModel<BriefCountryModel[]>> {
    return this.apiService.get<BriefCountryModel[]>('country/list/supported');
  }

  private fetchPaymentCountries(): Observable<ResponseModel<BriefCountryModel[]>> {
    return this.apiService.get<BriefCountryModel[]>('country/list/payment');
  }


  private sortPaymentCountries(country1, country2) {
    if (country1.comingSoon === country2.comingSoon) {
      return 0;
    } else if (country1.comingSoon && !country2.comingSoon) {
      return 1;
    }
    return -1;
  }
}


