import { NgModule } from '@angular/core';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { RoutingModule } from './routing/routing.module';
import { SharedModule } from './shared/shared.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LandingModule } from './features/landing/landing.module';
import { AuthService } from './core/services/auth.service';
import { StorageService } from './core/services/storage.service';
import { TransactionsService } from './core/services/transactions.service';
import { CountriesService } from './core/services/country.service';
import { NgxStripeModule } from 'ngx-stripe';
import { StoreModule } from '@ngrx/store';
import { NotificationReducer } from './store/reducers/notification.reducer';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    LeafletModule,
    BrowserAnimationsModule,
    NgbModalModule,
    HttpClientModule,
    SharedModule,
    RoutingModule,
    LandingModule,
    NgxStripeModule.forRoot(environment.stripePublKey, { locale: 'en' }),
    StoreModule.forRoot({
      notifications: NotificationReducer
    })
  ],
  providers: [
    AuthService,
    StorageService,
    TransactionsService,
    CountriesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
