import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'tcHightlight'
})
export class HightlightPipe implements PipeTransform {

	constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, args?: { value: string; color: string; bold: boolean }): any {
		if (!args) {
			return value;
		}

		let re = new RegExp(args.value.toString(), 'i');
		let match = re.exec(value.toString().trim());

		if (!match) {
			return value;
		}

		const newValue = value.toString().replace(re,
			`<span style="color: ${args.color}; ${args.bold ? 'font-weight: 500' : ''}">` + match[0].toString().toLowerCase() + '</span>');
		return this.sanitizer.bypassSecurityTrustHtml(newValue);
  }
}
