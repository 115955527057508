import { Component, OnInit, Input, forwardRef, HostBinding, ElementRef, HostListener, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { IInputProperties } from '../../interfaces/input';
import { state } from '../../interfaces/general';

const PROPERTIES_NAMES: string[] = ['prefix', 'suffix', 'prefixIcon', 'suffixIcon', 'placeholder'];

@Component({
	selector: 'tc-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TCInputComponent),
			multi: true
		}
	]
})
export class TCInputComponent implements ControlValueAccessor, OnInit, OnChanges {
	@HostBinding('class.tc-input') true;

	@HostBinding('class.input-sm') get smSize() {
		return this.size === 'sm';
	}

	@HostBinding('class.input-focus') get focus() {
		return this.inputFocus;
	}

	@HostBinding('class.input-notempty') get completedValue() {
		return this.value;
	}

	@HostBinding('class.input-disabled') @Input() disabled: boolean;
	@HostBinding('class.input-readonly') @Input() readonly: boolean;
	@Input() type: string;
	@Input() name: string;
	@Input() placeholder: string;
	@Input() charLimiting: number;
	@Input() prefix: string | string[];
	@Input() suffix: string | string[];
	@Input() prefixIcon: string | string[];
	@Input() suffixIcon: string | string[];
	@Input() size: string;
	@Input() required: boolean;
	@Input() autoSize: boolean;
	@Input() innerValue: string;
	@Input() bgColor: string | string[];
	@Input() borderColor: string | string[];
	@Input() color: string | string[];
	@Input() restrictSymbols: string;
	inputFocus: boolean;
	charLength: number;
	properties: IInputProperties;
	currentBgColor: string;
	currentBorderColor: string;
	currentColor: string;
	states: any;
	onChange: any = () => {
	};
	onTouched: any = () => {
	};

	constructor(public element: ElementRef) {
		this.type = 'text';
		this.name = '';
		this.size = 'md';
		this.inputFocus = false;
		this.readonly = false;
		this.disabled = false;
		this.required = false;
		this.autoSize = false;
		this.innerValue = '';
		this.properties = {
			prefixValue: '',
			prefixColor: '',
			suffixValue: '',
			suffixColor: '',
			prefixIconValue: '',
			prefixIconColor: '',
			suffixIconValue: '',
			suffixIconColor: ''
		};
		this.states = state;
	}

	ngOnInit() {
		this.charLength = this.charLimiting;

		if (this.autoSize) {
			setTimeout(() => {
				this.resizable(this.element.nativeElement.querySelector('.input-control'));
			}, 0);
		}

		this.setProperties();

		this.setStyles(!this.disabled ? this.states.default : this.states.disabled);
	}

	ngOnChanges() {
		this.setProperties();
	}

	setProperties() {
		PROPERTIES_NAMES.forEach(property => {
			const PROPERTY = this[property];

			this.properties[`${property}Value`] = PROPERTY instanceof Array ? PROPERTY[0] : PROPERTY;
			this.properties[`${property}Color`] = PROPERTY instanceof Array ? PROPERTY[1] : null;
		});
	}

	get value() {
		return this.innerValue;
	}

	set value(v) {
		if (v !== this.innerValue) {
			this.innerValue = v;
			this.onChange(v);
		}

		if (this.charLimiting > 0) {
			this.charLength = this.charLimiting - this.innerValue.length;
		}
	}

	registerOnChange(fn) {
		this.onChange = fn;
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

	writeValue(value: string) {
		if (value !== this.innerValue) {
			this.innerValue = value;
		}
	}

	onFocus(disabled: boolean) {
		if (!this.inputFocus && !disabled) {
			this.element.nativeElement.querySelector('.input-control').focus();
			this.inputFocus = true;
			this.setStyles(this.states.focus);
		}
	}

	onBlur(disabled: boolean) {
		this.inputFocus = false;

		if (!disabled) {
			this.onTouched();
			this.setStyles(this.states.default);
		}
	}

	@HostListener('mouseenter') onMouseEnter() {
		(!this.inputFocus && !this.disabled && !this.readonly) ? this.setStyles(this.states.hover) : null;
	}

	@HostListener('mouseleave') onMouseLeave() {
		(!this.inputFocus && !this.disabled && !this.readonly) ? this.setStyles(this.states.default) : null;
	}

	resizable(el: any, factor?: number) {
		const INT: number = Number(factor) || 7.7;

		function resize() {
			el.parentElement.style.maxWidth = ((el.value.length * INT) + 4) + 'px';
		}

		const e = 'keyup, keypress, focus, blur, change'.split(',');

		for (let i = 0; i < e.length; i++) {
			el.addEventListener(e[i], resize, false);
		}

		resize();
	}

	setStyles(
			st: state,
			bg: string | string[] = this.bgColor,
			border: string | string[] = this.borderColor,
			color: string | string[] = this.color
	) {
		let styleIndex: number = 0;

		switch (st) {
			case this.states.hover:
				styleIndex = 1;
				break;
			case this.states.focus:
				styleIndex = 2;
				break;
			case this.states.disabled:
				styleIndex = 3;
				break;
			default:
				styleIndex = 0;
		}

		this.currentBgColor = bg instanceof Array ? bg[styleIndex] : bg;
		this.currentBorderColor = border instanceof Array ? border[styleIndex] : border;
		this.currentColor = color instanceof Array ? color[styleIndex] : color;
	}

	getStyles() {
		return {
			'background-color': this.currentBgColor,
			'border-color': this.currentBorderColor,
			'color': this.currentColor
		};
	}
}
