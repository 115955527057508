import { Directive, Input, HostBinding, OnInit, HostListener, Output, EventEmitter, OnChanges, Renderer2, ElementRef } from '@angular/core';

@Directive({
	selector: '[tcAutocomplete]'
})
export class TCAutocompleteDirective implements OnInit, OnChanges {
	@Input() tcAutocomplete: Array<any>;
	@Input() key: string;
	
	@Output() resultsChanged: EventEmitter<Array<any>> = new EventEmitter();

	private queryField: string;
	private results: Array<any>;

	constructor(private el: ElementRef, private rend: Renderer2) {
		this.tcAutocomplete = [];
		this.key = '';
		this.queryField = '';
		this.results = [];
	}

	ngOnInit() {
	}

	@HostListener('input', ['$event.target.value'])
	public search(event): void {
		this.queryField = event;
		if (this.queryField !== '') {
			this.results = this.tcAutocomplete.filter((item: string) => {
				return (this.key ? item[this.key] : item).toString().toLowerCase().startsWith(this.queryField.toLowerCase().trim());
			});
		} else {
			this.results = [];
		}
		this.resultsChanged.emit(this.results);
	}

	ngOnChanges() {
		this.ngOnInit();
	}
}
