import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ResponseModel } from '../models/response-wrapper.model';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';

const options = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': ''
  },
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {
  protected baseUrl = environment.api;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private router: Router
  ) {
  }

  private getUrl(url: string = ''): string {
    return this.baseUrl + url;
  }

  private getOptions() {
    const token = this.storage.getToken();
    options.headers.Authorization = token ? token : '';
    return options;
  }

  public get<T = any>(url: string = ''): Observable<ResponseModel<T>> {
    return this.http.get<ResponseModel<T>>(this.getUrl(url), this.getOptions())
      .pipe(catchError(error => {
        return this.errorHandler(error);
      }));
  }

  public post<T = any>(url: string = '', data: any = {}): Observable<ResponseModel<T>> {
    return this.http.post<ResponseModel<T>>(this.getUrl(url), data, this.getOptions())
      .pipe(catchError(error => {
        return this.errorHandler(error);
      }));
  }

  public put<T = any>(url: string = '', data: any = {}): Observable<ResponseModel<T>> {
    return this.http.put<ResponseModel<T>>(this.getUrl(url), data, this.getOptions())
      .pipe(catchError(error => {
        return this.errorHandler(error);
      }));
  }

  public delete<T = any>(url: string = ''): Observable<ResponseModel<T>> {
    return this.http.delete<ResponseModel<T>>(this.getUrl(url));
  }

  private errorHandler(error: HttpErrorResponse) {
    let responseModel: ResponseModel<any> = new ResponseModel<any>();
    try {
      responseModel = error.error as ResponseModel<any>;
      console.log('ApiService.errorHandler statusCode - ' + responseModel.statusCode);
      if (responseModel.statusCode === 401) {
        this.router.navigate(['/auth/login']);
      }
      // if (responseModel.statusCode === 404) {
      // 	this.router.navigate(['/']);
      // }
      // if (responseModel.statusCode === 500) {
      // 	this._router.navigate(['/']);
      // }
      return new Observable<ResponseModel<any>>(data => data.next(responseModel));
    } catch (err) {
      return throwError(error);
    }
  }
}
