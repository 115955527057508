import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.scss']
})
export class AboutUsPageComponent implements OnInit {
  bannerContent = `<p>Valley Pay Application provides affordable, secure, simple,
                      fast, and flexible money transfer
                      services to meet and exceed all our customer's expectations.
                   </p>`;

  constructor() {
  }

  ngOnInit(): void {
  }

}
