import { Injectable } from '@angular/core';
import { BriefUserModel } from '../models/user.models';
import { LoginResponseModel } from '../models/auth.models';

export enum StorageKey {
  Token = 'tokenVP',
  User = 'userVP'
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  getToken(): string {
    const token: string = this.get(StorageKey.Token);
    if (!token || token === '') {
      return null;
    }
    return 'Bearer ' + token;
  }

  getUser(): BriefUserModel {
    const user: BriefUserModel = this.getObject(StorageKey.User);
    if (!user) {
      return null;
    }
    return user;
  }

  setLoginData(data: LoginResponseModel) {
    if (!data || !data.authToken) {
      return;
    }

    this.setObject(StorageKey.User, data.user);
    this.set(StorageKey.Token, data.authToken);
  }

  setUserData(user: BriefUserModel) {
    if (!user) {
      return;
    }

    this.setObject(StorageKey.User, user);
  }

  clearLoginData() {
    this.remove(StorageKey.Token);
    this.remove(StorageKey.User);
  }

  set(key: StorageKey, value) {
    if (!value) {
      return;
    }
    localStorage.setItem(key.toString(), value);
  }

  setObject(key: StorageKey, value) {
    if (!value) {
      return;
    }
    localStorage.setItem(key.toString(), JSON.stringify(value));
  }

  get(key: StorageKey) {
    return localStorage.getItem(key.toString());
  }

  getObject(key: StorageKey) {

    const jsonStr: string = localStorage.getItem(key.toString());
    if (!jsonStr) {
      return null;
    }
    return JSON.parse(jsonStr);
  }

  remove(key: StorageKey) {
    localStorage.removeItem(key.toString());
  }
}

